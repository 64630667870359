import {
  computePosition,
  flip,
  shift,
  offset,
  type ComputePositionConfig,
} from "@floating-ui/dom";
import type { Action } from "svelte/action";

type FloatingOptions = {
  trigger: HTMLElement;
  placement?: ComputePositionConfig["placement"];
  gutter?: number;
};

/**
 * Action wrapper around floating ui
 */
/* v8 ignore start: just configures Floating UI */
export const floating: Action<HTMLElement, FloatingOptions> = (node, opts) => {
  node.style.position = "absolute";
  node.style.left = "0px";
  node.style.top = "0px";

  async function position(opts: any) {
    if (!opts.trigger) {
      console.warn("No trigger given for floating ui");
      return;
    }

    const { x, y } = await computePosition(opts.trigger, node, {
      placement: opts.placement ?? "top-start",
      middleware: [
        offset(opts.gutter ?? 4),
        flip(),
        shift({ padding: opts.gutter ?? 4 }),
      ],
    });

    node.style.left = `${x}px`;
    node.style.top = `${y}px`;
  }

  position(opts);

  return {
    update(newOpts: FloatingOptions) {
      position(newOpts);
    },
  };
};
/* v8 ignore stop */
