<!--
### Textarea form input
 -->
<script lang="ts">
  import autosize from "autosize";
  import { onMount, untrack, type ComponentProps } from "svelte";
  import FormField from "../FormField/FormField.svelte";
  import type { HTMLTextareaAttributes } from "svelte/elements";

  type TextareaProps = {
    // The value of the textarea
    value?: string;
    // Whether the textarea is minimal
    minimal?: boolean;
    // Whether the textarea is busy
    busy?: boolean;
  } & ComponentProps<typeof FormField> &
    HTMLTextareaAttributes;

  let {
    value = $bindable(),
    minimal = false,
    label,
    layout = "normal",
    error,
    busy,
    tip,
    labelContent,
    class: className,
    ...props
  }: TextareaProps = $props();

  let textarea: HTMLTextAreaElement;

  $effect(() => {
    if (textarea) {
      autosize(textarea);
    }
  });

  $effect(() => {
    if (value !== undefined) {
      autosize.update(textarea);
    }
  });
</script>

<style>
  .minimal {
    border: none;
  }

  .textarea {
    width: 100%;
  }
</style>

<FormField
  {label}
  {error}
  {tip}
  {busy}
  {layout}
  {labelContent}
  class={className}
>
  <textarea
    bind:this={textarea}
    bind:value
    class="--input textarea"
    class:minimal
    disabled={busy}
    {...props}
  ></textarea>
</FormField>
