<!--
### Form field with label
 -->
<script lang="ts">
  import type { HTMLAttributes } from "svelte/elements";
  import Tooltip from "../Tooltip/Tooltip.svelte";
  import Button from "../Button/Button.svelte";
  import type { Snippet } from "svelte";

  type FormFieldProps = {
    /** Optional label */
    label?: string | null;
    /** Optional extra content on the label line*/
    labelContent?: Snippet;
    /** Optional error message */
    error?: string | null;
    /** Layout of the field */
    layout?: "normal" | "error-below";
    /** Whether field is busy */
    busy?: boolean;
    /** Optional tooltip for the field */
    tip?: string;
  } & Omit<HTMLAttributes<HTMLDivElement>, "onchange">;

  const {
    label = "",
    labelContent,
    error = "",
    layout = "normal",
    tip,
    busy,
    children,
    class: className = "",
    ...props
  }: FormFieldProps = $props();
</script>

<style>
  .field {
    transition: opacity 250ms var(--ease-standard);
  }

  .label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--size-2);
  }

  .label-inner {
    display: flex;
    align-items: flex-start;
    font: var(--text-sm);
    font-weight: var(--weight-medium);
    color: var(--field-label-color, var(--color-text-secondary));
    margin-right: var(--size-6);
    white-space: nowrap;
  }

  .error {
    font: var(--text-xs);
    color: var(--color-error);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .error-below {
    display: flex;
    justify-content: flex-end;
    margin-top: var(--size-2);
  }
</style>

<div class="field {className}" {...props} class:--busy={busy}>
  {#if !!label || (error && layout !== "error-below")}
    <div class="label">
      <div class="label-inner">
        {label}
        {#if tip}
          <Tooltip {tip} />
        {/if}
      </div>
      {#if labelContent && !error && layout === "normal"}
        <div class="label-content">
          {@render labelContent()}
        </div>
      {/if}
      {#if !!error && layout !== "error-below"}
        <span class="error">{error}</span>
      {/if}
    </div>
  {/if}
  {#if children}
    {@render children()}
  {/if}
  {#if error && layout === "error-below"}
    <div class="error-below">
      <span class="error">{error}</span>
    </div>
  {/if}
</div>
